<template>
  <div v-if="!error" id="preloader" class="d-flex justify-center align-center">
    <span class="body-1" style="cursor: default; margin-top: 180px;">
      Generando link de pago, por favor espere ...
    </span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      error: false,
      reserva: this.$route.query.reserva
    }
  },
  async created () {
    if (!this.reserva) {
      this.$store.dispatch('executeModalAviso', {
        titulo: 'No se pudo generar el Link de Pago',
        texto: 'Se debe especificar el id de la reserva',
        icono: 'fas fa-exclamation-circle',
        color: 'error'
      })
      this.error = true
      return
    }
    // verifico q no exista la misma reserva
    let existe = await this.$store.dispatch('botonPago/validarReservaAutomatica', this.reserva)

    if (existe.exito != 1) {
      this.$store.dispatch('executeModalAviso', {
        titulo: 'No se pudo generar el Link de Pago',
        texto: 'No se pudo validar el N° de la Reserva',
        icono: 'fas fa-exclamation-circle',
        color: 'error'
      })
      this.error = true
      return
    }

    // si me devolvio un zelda es pq existe
    if (existe.link) {
      return window.open(existe.link, "_self")
    }

    // obtengo los datos de la reserva
    let result = await this.$store.dispatch('botonPago/getReservaWeb', this.reserva)

    if (!result.exito) {
      this.$store.dispatch('executeModalAviso', {
        titulo: 'No se pudo generar el Link de Pago',
        texto: result.message,
        icono: 'fas fa-exclamation-circle',
        color: 'error'
      })
      this.error = true
      return
    }

    let param = {
      importe: result.total,
      descripcion: 'RADA - Reserva N° ' + this.reserva,
      reserva: this.reserva.toString(),
      telefono: result.telefono,
      correo: result.email,
      ingresa_financiacion: 0,
      ingresa_tipo_tarjeta: 0,
      ingresa_forma_pago: 0,
      empresa: 5,
      ipPublica: window.location.origin + '/',
      automatico: 1
    }

    // creación del boton
    let createPeticion = await this.$store.dispatch('botonPago/crearBotonPago', param, 1)

    if (createPeticion.resultado == 1) {
      window.open(createPeticion.link, "_self")
    } else {
      this.$store.dispatch('executeModalAviso', {
        titulo: 'No se pudo generar el Link de Pago',
        texto: createPeticion.msj,
        icono: 'fas fa-exclamation-circle',
        color: 'error'
      })
      this.error = true
    }
  }
}
</script>

<style scoped>
#preloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: visible;
    background: #ffffff url(../assets/preloader.gif) no-repeat center center;
}
</style>